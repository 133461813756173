@import "../../styles/variables/_colors.scss";
@import "../../styles/variables/devices.scss";
@import "../../styles/mixins.scss";

.wrapper {
  @include mobile {
    &:not(.resizable) {
      display: none;
    }

    &.mobile.resizable {
      display: flex;
      background-color: var(--header-background-color);
      height: var(--header-height);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      align-content: center;
      text-align: center;

      img {
        height: 24px;
        margin: auto;
      }

      [dir="rtl"] & {
        right: 0;
      }
    }
  }

  &:global(.not-mobile) {
    img {
      width: 386px;
    }
  }
}
